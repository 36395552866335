import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Heading, Button, Box, HStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputField, SelectInput } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import {
  createLoanOffer,
  getLoanOffers,
  editLoanOffer,
} from "redux/actions/settingsActions";
import { Option, RootState } from "types";
import { LoanProduct } from "types/responses";

interface ILoanOfferConfigProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  isEdit?: any | undefined;
}

interface LoanOfferFormValues {
  interestRate: string;
  tenure: string;
  maxAmount: string;
  minAmount: string;
  loanProduct: string;
  minAge: string;
  maxAge: string
}

export default function LoanOfferConfig({
  showModal,
  setShowModal,
  isEdit,
}: ILoanOfferConfigProps) {
  const dispatch = useDispatch();
  const [loanProducts, setLoanProducts] = useState<Record<string, LoanProduct>>(
    {}
  );
  const [selectedId, setSelectedId] = useState("1");
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const products = setting?.loanProducts;
  const xJwt = localStorage.getItem("x_jwt");
  const offerId = isEdit?._id;

  useEffect(() => {
    const loanProducts: Record<string, LoanProduct> = {};

    for (const product of products) {
      loanProducts[product.externalId] = product;
    }

    setLoanProducts(loanProducts);
  }, [products]);

  useEffect(() => {
    if (isEdit) {
      setSelectedId(isEdit.loanProduct);
    }
  }, [isEdit]);

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    dispatch(getLoanOffers(xJwt));
  };

  return (
    <>
      <Modal
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="80%"
        className="loan-config-modal"
        afterClose={handleClose}
      >
        <Box textAlign="center">
          <Heading as="h2" fontSize="md" mb="3">
            {isEdit !== undefined ? "Edit Loan Offer" : "Create Loan Offer"}
          </Heading>
          <Formik
            initialValues={{
              loanProduct: isEdit !== undefined ? isEdit?.loanProduct : "",
              interestRate: isEdit !== undefined ? isEdit?.interestRate : "",
              tenure: isEdit !== undefined ? isEdit?.tenure : "",
              maxAmount: isEdit !== undefined ? isEdit?.maxAmount : "",
              minAmount: isEdit !== undefined ? isEdit?.minAmount : "",
              maxAge: isEdit !== undefined ? (isEdit?.maxAge || "0") : "",
              minAge: isEdit !== undefined ? (isEdit?.minAge || "0") : "",
            }}
            onSubmit={(values: LoanOfferFormValues, { resetForm }) => {
              const loanOfferBody = {
                interestRate: parseFloat(values.interestRate),
                tenure: parseFloat(values.tenure),
                maxAmount: parseFloat(values.maxAmount),
                minAmount: parseFloat(values.minAmount),
                loanProduct: values.loanProduct,
                minAge: values.minAge,
                maxAge: values.maxAge,
              };
              if (isEdit !== undefined) {
                dispatch(editLoanOffer(xJwt, loanOfferBody, offerId));
              } else {
                dispatch(createLoanOffer(xJwt, loanOfferBody));
              }
              resetForm();
              handleCancel();
            }}
            validationSchema={yup.object().shape({
              loanProduct: yup.string().required("Please select loan product"),
              interestRate: yup
                .number()
                .max(
                  loanProducts[selectedId]?.maximumInterest,
                  `interest rate must be less than or equal to loan product maximum - ${loanProducts[selectedId]?.maximumInterest}`
                )
                .min(
                  loanProducts[selectedId]?.minimumInterest,
                  `interest rate must be greater than or equal to loan product minimum - ${loanProducts[selectedId]?.minimumInterest}`
                )
                .required(`Please enter interest rate`),
              tenure: yup
                .number()
                .max(
                  loanProducts[selectedId]?.maximumTenure,
                  `tenure must be less than or equal to loan product maximum - ${loanProducts[selectedId]?.maximumTenure}`
                )
                .min(
                  loanProducts[selectedId]?.minimumTenure,
                  `tenure must be greater than or equal to loan product minimum - ${loanProducts[selectedId]?.minimumTenure}`
                )
                .required(`Please enter tenure`),
              maxAmount: yup
                .number()
                .max(
                  loanProducts[selectedId]?.maximumAmount,
                  `amount must be less than or equal to loan product maximum - ${loanProducts[selectedId]?.maximumAmount}`
                )
                .min(
                  loanProducts[selectedId]?.minimumAmount,
                  `amount must be greater than or equal to loan product minimum - ${loanProducts[selectedId]?.minimumAmount}`
                )
                .required(`Please enter max amount`),
              minAmount: yup
                .number()
                .max(
                  loanProducts[selectedId]?.maximumAmount,
                  `amount must be less than or equal to loan product maximum - ${loanProducts[selectedId]?.maximumAmount}`
                )
                .min(
                  loanProducts[selectedId]?.minimumAmount,
                  `amount must be greater than or equal to loan product minimum - ${loanProducts[selectedId]?.minimumAmount}`
                )
                .required(`Please enter min amount`),
            })}
          >
            {({ values, handleChange, setFieldValue, handleBlur, errors }) => (
              <Form>
                <Box className="half-form" w="100%">
                  <SelectInput
                    id="loanProduct"
                    hasLabel
                    label="Loan Product"
                    placeholder="Select loan product"
                    value={
                      values.loanProduct
                        ? {
                            value: values.loanProduct,
                            label: loanProducts[values.loanProduct]?.name,
                          }
                        : null
                    }
                    options={products.map(({ externalId, name }) => ({
                      value: externalId,
                      label: name,
                    }))}
                    onChange={(id, selectedValue) => {
                      const { value } = selectedValue as Option;

                      setSelectedId(value);
                      setFieldValue(id, value);
                    }}
                    error={errors.loanProduct}
                  />
                </Box>
                <HStack justify="space-between">
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="interestRate"
                      hasLabel
                      label="Interest Rate (%)"
                      placeholder="Enter Interest Rate"
                      value={values.interestRate}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.interestRate}
                    />
                  </Box>
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="tenure"
                      hasLabel
                      label="Tenure"
                      placeholder="Enter Tenure"
                      value={values.tenure}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.tenure}
                    />
                  </Box>
                </HStack>

                <HStack justify="space-between">
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="maxAmount"
                      hasLabel
                      label="Maximum Amount"
                      placeholder="Enter Maximum Amount"
                      value={values.maxAmount}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.maxAmount}
                    />
                  </Box>
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="minAmount"
                      hasLabel
                      label="Minimum Amount"
                      placeholder="Enter Minimum Amount"
                      value={values.minAmount}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.minAmount}
                    />
                  </Box>
                </HStack>

                <HStack justify="space-between">
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="maxAge"
                      hasLabel
                      label="Maximum Age Requirement"
                      placeholder="Enter Maximum Age"
                      value={values.maxAge}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.maxAge}
                    />
                  </Box>
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="minAge"
                      hasLabel
                      label="Minimum Age Requirement"
                      placeholder="Enter Minimum Age"
                      value={values.minAge}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.minAge}
                    />
                  </Box>
                </HStack>

                <Button type="submit" mt="3">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}
